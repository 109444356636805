'use strict';
(function () {

  class SiteComponent {
    constructor(Auth, Util, usSpinnerService, appConfig, Site, Turbine, $stateParams, $state, $scope,
      $window, $http, $uibModal, toastr, Modal, ActivityLog, SharedUrl, $sce) {
      this.Site = Site;
      this.Turbine = Turbine;
      this.Modal = Modal;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.$stateParams = $stateParams;
      this.$http = $http;
      this.$uibModal = $uibModal;
      this.toastr = toastr;
      this.usSpinnerService = usSpinnerService;
      this.appConfig = appConfig;
      this.isLoggedIn = Auth.isLoggedIn;
      this.getAddress = Util.getAddress;
      this.getTotalCapacity = Util.getTotalCapacity;
      this.API_KEY = this.appConfig.GOOGLE_API_KEY;
      this.site = {};
      this.components = [];
      this.$state = $state;
      this.mapImageName = '';
      this.ActivityLog = ActivityLog;
      this.SharedUrl = SharedUrl;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      this.$sce = $sce;
      this.sorting = 'sp';
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
          this.init();
        } else {
          $state.go('login');
        }
      });

      this.graphWidth = 250;
      this.graphHeight = 250;

      let w = angular.element($window);

      $scope.getWindowDimensions = function () {
        return {
          'h': w.height(),
          'w': w.width()
        };
      };

      $scope.$watch($scope.getWindowDimensions, (newValue, oldValue) => {
        if (newValue.w < 768) {
          this.graphWidth = newValue.w - 40;
          this.graphHeight = 250;
        } else if (newValue.w >= 768 && newValue.w <= 992) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else if (newValue.w > 992 && newValue.w <= 1200) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else if (newValue.w > 1200 && newValue.w <= 1360) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else {
          this.graphWidth = 212;
          this.graphHeight = 250;
        }

      }, true);

      w.bind('resize', function () {
        $scope.$apply();
      });
    }

    $onInit() {
    }

    init() {
      // this.sortingSite();
      this.loading = true;
      this.Site.get({ id: this.$stateParams.id }).$promise
        .then(response => {
          if (response.data) {
            this.site = response.data;
            // console.log('site: ', this.site);
            this.site.findingsExists = false;
            var totalFindingCount = 0;
            totalFindingCount = this.site.findingsCount.cat1 + this.site.findingsCount.cat2
              + this.site.findingsCount.cat3 + this.site.findingsCount.cat4
              + this.site.findingsCount.cat5;
            if (this.site.findingsCount.cat1 ||
              this.site.findingsCount.cat2 ||
              this.site.findingsCount.cat3 ||
              this.site.findingsCount.cat4 ||
              this.site.findingsCount.cat5) {
              this.site.findingsExists = true;
            }

            // set map image
            this.getHighestColorImage();
            /* Chart options */
            this.options = {
              chart: {
                type: 'pieChart',
                width: this.graphWidth,
                height: this.graphHeight,
                donut: true,
                title: totalFindingCount,
                x: function (d) {
                  return d.key;
                },
                y: function (d) {
                  return d.y;
                },
                showLegend: true,
                showLabels: true,
                showValues: true,
                valueFormat: function (d) {
                  return d3.format(',.0f')(d);
                },
                labelType: function (d) {
                  return d.value
                },
                color: function (d, i) {
                  return (d && d.color);
                },
                duration: 500
              }
            };
            this.site._data = [
              {
                key: "Cat 1 : Low",
                y: this.site.findingsCount.cat1,
                color: this.appConfig.colors.cat1
              },
              {
                key: "Cat 2 : Medium",
                y: this.site.findingsCount.cat2,
                color: this.appConfig.colors.cat2
              },
              {
                key: "Cat 3 : Serious",
                y: this.site.findingsCount.cat3,
                color: this.appConfig.colors.cat3
              },
              {
                key: "Cat 4 : Major",
                y: this.site.findingsCount.cat4,
                color: this.appConfig.colors.cat4
              },
              {
                key: "Cat 5 : Critical",
                y: this.site.findingsCount.cat5,
                color: this.appConfig.colors.cat5
              }
            ];
          }
          this.loading = false;
          this.stopSpin('spinner-1');
        })
        .catch(err => {
          console.log(err);
          this.stopSpin('spinner-1');
          this.loading = false;
          this.site = null;
        })
    }

    // filterSitesByFindingCategory(findingsCategory) {
    //   let isAnyCategorySelected = false;
    //     this.allSortedSites = _.filter(this.site.turbines, site => {
    //       if((findingsCategory.cat5 && site.findingsCount.cat5 > 0) || (findingsCategory.cat4 && site.findingsCount.cat4 > 0) || (findingsCategory.cat3 && site.findingsCount.cat3 > 0) || (findingsCategory.cat2 && site.findingsCount.cat2 > 0) || (findingsCategory.cat1 && site.findingsCount.cat1 > 0) || (findingsCategory.cat0 && site.findingsCount.cat0 > 0) ) {
    //         isAnyCategorySelected = true;
    //         return site;
    //       }
    //     });
    //     if(!isAnyCategorySelected) {
    //       this.allSortedSites = this.allSites;
    //       this.sortingSite();
    //     }
    // }

    sortingTurbines() {
      if (this.sorting == 'hfc') {
        this.site.turbines =_.sortBy(this.site.turbines, [(turbine) => {
          let finding = this.site.findingsCount.turbineCategory ? this.site.findingsCount.turbineCategory[turbine._id] : null;
          return finding ? -finding.category : 0;
        }, (turbine) => {
          let categoryFindingCount = 0;
          let finding = this.site.findingsCount.turbineCategory ? this.site.findingsCount.turbineCategory[turbine._id] : null;
          if(finding && finding.category) {
            categoryFindingCount = turbine.findingsCount[`cat${finding.category}`];
          }
          return -categoryFindingCount;
        }]);
      } else if(this.sorting == 'sp') {
        this.site.turbines = _.sortBy(this.site.turbines, turbine => {
          return !_.isNil(turbine.sitePositionV2) ? turbine.sitePositionV2 : 'zzzzzz';
        });
      } else if(this.sorting == 'mrd') {
        this.site.turbines = _.sortBy(this.site.turbines, turbine => {
          return -(new Date(turbine.findingRepairAddedAt)).getTime();
        });
      }
    }

    exportFindings() {
      this.$http({
        method: 'GET',
        url: "/api/v1/findings/export/site/" + this.site._id,
        // This is important! Tell it to expect an arraybuffer
        responseType: "arraybuffer"
      })
        .success((data, status, headers, config) => {
          // use the saveAs library to save the buffer as a blob on the user's machine. Use the correct MIME type!
          saveAs(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), this.site.name + ".xlsx");

          const activityLogData = {
            site: this.site._id,
            user: this.currentUser._id,
            source: 'web',
            action: 'export findings',
            type: 'site',
            sourceScreen: 'site',
            eventString: `Findings for Wind farm <a href="/site/${this.site._id}">${this.site.name}</a> was exported by ${this.currentUser.name}`
          }
          this.ActivityLog.save(activityLogData).$promise
            .then(activityLog => {
              console.log(activityLog);
            })
            .catch(err => {
              console.log('Error inserting activityLog', err);
          });
        })
        .error((data, status, headers, config) => {
          console.log(data);
        });
    }

    exportRepairs() {
      this.$http({
        method: 'GET',
        url: "/api/v1/repairs/export/site/" + this.site._id,
        // This is important! Tell it to expect an arraybuffer
        responseType: "arraybuffer"
      })
        .success((data, status, headers, config) => {
          // use the saveAs library to save the buffer as a blob on the user's machine. Use the correct MIME type!
          saveAs(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), this.site.name + ".xlsx");

          const activityLogData = {
            site: this.site._id,
            user: this.currentUser._id,
            source: 'web',
            action: 'export repairs',
            type: 'site',
            sourceScreen: 'site',
            eventString: `Repairs for Wind farm <a href="/site/${this.site._id}">${this.site.name}</a> was exported by ${this.currentUser.name}`
          }
          this.ActivityLog.save(activityLogData).$promise
            .then(activityLog => {
              console.log(activityLog);
            })
            .catch(err => {
              console.log('Error inserting activityLog', err);
          });
        })
        .error((data, status, headers, config) => {
          console.log(data);
        });
    }

    mergeTurbines() {
      // console.log('turbines', this.site.turbines);
      let selectedTurbines = _.filter(this.site.turbines, { checked: true });
      if (!(selectedTurbines && selectedTurbines.length > 1)) {
        console.log('please select at least two turbines.');
        this.toastr.error('please select at least two turbines.');
        return;
      }
      //let duplicateTurbines = _.filter(selectedTurbines, { serial: selectedTurbines[0].serial });
      // if(selectedTurbines.length == duplicateTurbines.length) {
      console.log('checked correctly');
      this.Modal.confirm.show(success => {
        this.Turbine.merge({}, { site: this.site._id, serial: selectedTurbines[0].serial, turbineIds: _.map(selectedTurbines, turbine => turbine._id) }).$promise
        .then(turbine => {
          this.toastr.success('Turbines merged successfully.');
          // this.$state.go('main');
          this.$state.reload();
        })
        .catch(err => {
          console.log(err);
          this.error = err;
          this.toastr.error('Something went wrong. please try later or contact administrator.');
        });
      },'Confirm Merge', `<p>Are your sure you want to merge ${selectedTurbines.length} turbines.</p>`);
      // } else {
      // console.log('please select (duplicate) turbines with same serial number.');
      // this.toastr.error('please select (duplicate) turbines with same serial number.');
      // }
    }

    getHighestColorImage() {
      // console.log('scope.turbine: ', this.$scope.turbine);
      // console.log('getHighestColorImage finding: ', this.$scope.turbine.findingsCount.turbineCategory[this.$scope.turbine._id]);
      // console.log('max category: ', _.maxBy(findings, 'category'));
      if (this.site.findingsCount.cat5 > 0) {
        this.mapImageName = 'critical';
      } else if (this.site.findingsCount.cat4 > 0) {
        this.mapImageName = 'major';
      } else if (this.site.findingsCount.cat3 > 0) {
        this.mapImageName = 'serious';
      } else if (this.site.findingsCount.cat2 > 0) {
        this.mapImageName = 'medium';
      } else if (this.site.findingsCount.cat1 > 0) {
        this.mapImageName = 'low';
      } else {
        this.mapImageName = 'fan';
      }
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    openEditSiteModal() {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/site/addEditSiteModal/addEditSiteModal.html',
        controller: 'AddEditSiteModalCtrl',
        controllerAs: 'aESite',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            site: this.site,
            title: 'Edit Site',
            name: this.site.organization.name
          }
        }
      });
      modalInstance.result.then(() => {
        console.log('site edit success');
        this.init();
      });
    }

    shareUrl() {
      const shareUrlData = {
        url: `site/${this.site._id}`,
        level: 'Windfarm',
        organization: this.currentUser.organization._id,
      }
      var url = window.location.href;
      var urlArray = url.split("/");
      var baseUrl = urlArray[0] + "//" + urlArray[2];
      this.SharedUrl.save(shareUrlData).$promise
        .then(sharedUrlResponse => {
          this.urlShared = `${baseUrl}/shared/${sharedUrlResponse.data._id}`;
          this.htmlPopover = this.$sce.trustAsHtml(`${this.urlShared} <i>Users without an account will see a read-only version of this page.</i>`);
          this.showShareUrlPopup = true;
          var dummyElement = document.createElement("textarea");
          document.body.appendChild(dummyElement);
          dummyElement.value = this.urlShared;
          dummyElement.select();
          document.execCommand("copy");
          document.body.removeChild(dummyElement);
          this.toastr.success('Link copied successfully.');
        })
        .catch(err => {
          console.log('Error inserting sharedUrl', err);
      });
    }

    openBatchImportModal() {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/batch-import/batch-import-modal.html',
        controller: 'BatchImportModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Batch Import Images',
            site: this.site
          }
        }
      });
      modalInstance.result.then(() => {
        this.init();
      });
    }

    openBatchImportModalforFinding() {
    
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/batch-import-finding/batch-import-modal-finding.html',
        controller: 'BatchImportFindingCtrl',
        
        controllerAs: 'bif',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Batch Import Images',
            site: this.site
          }
        }
      });
      modalInstance.result.then(() => {
        this.init();
      });
    }
  }

  angular.module('windmanagerApp')
    .component('site', {
      templateUrl: 'app/site/site.html',
      controller: SiteComponent,
      controllerAs: 'sc'
    });

})();
